import React from "react"
import { Layout, Breadcrumbs} from '@common'
import { AttentionSection } from '@sections'
import './styles/page.scss'

const Page = ({pageContext}) => {
  const {page} = pageContext
  return(
  <Layout seo={pageContext.page.seo}
			  siteMetaData={pageContext.page.siteMetaData}>
			<AttentionSection height={350} title={page.title}/>
			  
      <div className='page'>
				<div className='page-content'>
					<div className='container'>
						<div className='row'>
							<div className='col' />
						</div>
					</div>
					<div className='container'>
						<div className='row justify-content-center'>
							<div className='col-12 col-md-8'>
								<Breadcrumbs
									elements={[
										{
											label: page.title,
											url: `/${page.slug}/`,
										},
									]}
								/>
								<div
									className='page-inner'
									dangerouslySetInnerHTML={{
										__html: page.content,
									}}
								/>
							</div>
						</div>
					</div>
				</div>
    </div>
  </Layout>
)}

export default Page
